import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/sys/role/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/sys/role/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/sys/role/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/sys/role/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/sys/role/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/sys/role/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/sys/role/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function selectAll() {
  return request.get(constant.serverUrl + "/sys/role/selectAll");
}

function queryRelatedPerms(roleId) {
  return request.get(constant.serverUrl + "/sys/role/queryRelatedPerms?roleId=" + roleId);
}

function saveRelatedPermission(formModel) {
  return request.post(constant.serverUrl + "/sys/role/saveRelatedPermission", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function queryRelatedMenuList(roleId) {
  return request.get(constant.serverUrl + "/sys/role/queryRelatedMenuList?roleId=" + roleId);
}

function saveRelatedMenu(formModel) {
  return request.post(constant.serverUrl + "/sys/role/saveRelatedMenu", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  create, edit, add, update, remove, batchRemove,
  pageList, selectAll,
  queryRelatedPerms, queryRelatedMenuList,
  saveRelatedPermission, saveRelatedMenu
}