<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">系统管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/sys/user">用户管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
      <el-form-item label="用户名" prop="userName">
        <el-input type="text" size="mini" v-model="queryModel.userName"></el-input>
      </el-form-item>
      <el-form-item label="真实名称" prop="realName">
        <el-input type="text" size="mini" v-model="queryModel.realName"></el-input>
      </el-form-item>
      <el-form-item label="所属单位" prop="companyId">
        <!-- <el-select
          v-model="queryModel.companyId"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:200px"
        >
          <el-option
            v-for="company in companyResult"
            :key="company.id"
            :label="company.name"
            :value="company.id"
          ></el-option>
        </el-select> -->
        <el-select-tree
          size="mini"
          :props="props"
          :options="companyResult"
          v-model="queryModel.companyId"
          height="200"
        ></el-select-tree>
      </el-form-item>
      <el-form-item label="角色" prop="roleId">
        <el-select
          v-model="queryModel.roleId"
          size="mini"
          filterable
          placeholder="请选择"
          style="width:200px"
        >
          <el-option
            v-for="role in roleResult"
            :key="role.id"
            :label="role.description"
            :value="role.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
        >查询</el-button>&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
        >重置</el-button>&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-remove"
        :disabled="multipleSelection.length==0"
        @click="handleBatchDelete"
      >删除选中项</el-button>
    </el-row>
    <el-table
      :data="tableData"
      style="min-height:400px;"
      v-loading="loading"
      stripe
      border
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="userName"
        sort-by="user_name"
        label="用户名"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="realName"
        sort-by="real_name"
        label="真实名称"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        sort-by="company_name"
        label="所属单位"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        sort-by="create_time"
        label="创建时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        sort-by="update_time"
        label="更新时间"
        sortable="custom"
        width="180"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{row}">
          <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
          <el-button size="mini" type="primary" @click="openChangePw(row)">修改密码</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <user-detail
      v-if="showModal"
      ref="userDetail"
      :businessKey="businessKey"
      :companyResult="companyResult"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></user-detail>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      style="width:100%"
    >
      <el-form>
        <el-form-item label="新密码" prop="password">
          <el-input v-model="newPassword" placeholder="请输入新密码" style="width:50%" show-password></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="changePw">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import UserDetail from "./user-detail";
import userApi from "@/api/sys/user";
import roleApi from "@/api/sys/role";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  name: 'SysUserList',
  data() {
    var self = this;

    return {
      queryModel: {
        userName: "",
        realName: "",
        companyId:"",
        roleId:""
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      modalTitle: "",      
      businessKey: "",
      showModal: false,
      companyResult:[],
      dialogFormVisible:false,
      newPassword:"",
      roleResult:[],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children"
      },
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      console.log(pageIndex);

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("userName", self.queryModel.userName);
      formData.append("realName", self.queryModel.realName);

      if (self.queryModel.companyId != null) {
        formData.append("companyId", self.queryModel.companyId);
      }

      formData.append("roleId", self.queryModel.roleId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      self.loading = true;

      userApi.pageList(formData).then(function(response) {
        self.loading = false;

        var jsonData = response.data;

        if(jsonData.result){
          var pageInfo = jsonData.data;

          self.tableData = pageInfo.data;
          self.totalPages = pageInfo.totalPages;
          self.totalElements = pageInfo.recordsTotal;
        }
        else {
          self.$message({
              message: jsonData.message + "",
              type: "warning"
            });
        }
      }).catch((error)=>{
        self.loading = false;
      });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      var self = this;
      
      self.modalTitle = "新增用户";
      self.businessKey = "";
      self.showModal = true;
    },
    handleEdit(record) {
      var self = this;
      
      self.modalTitle = "编辑用户";
      self.businessKey = record.id;
      self.showModal = true;
    },
    handleDelete(record) {
      var self = this;

      this.$confirm("是否确认删除" + record.userName + "？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        userApi.remove(record.id).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            // var index = self.tableData.indexOf(record);
            // self.tableData.splice(index, 1);
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map(record => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        userApi.batchRemove(idList).then(function(response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!"
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      this.showModal = false;

      if(refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    openChangePw(record) {
      var self = this;
      self.changeId = record.id;
      self.dialogFormVisible = true;
    },
    changePw() {
      var self = this;
      var formData = new FormData();

      formData.append("userId",  self.changeId);
      formData.append("newPwd", self.newPassword);

      userApi.changeUserPassword(formData).then(function(response) {
        var jsonData = response.data;

        if (jsonData.result) {
          self.changePage(self.pageIndex);

          self.dialogFormVisible = false;
          self.changeId = "";
          self.newPassword = "";

          self.$message({
            type: "success",
            message: "修改成功!"
          });
        }
      });
    }
  },
  mounted: function() {
    var self = this;
    this.changePage(1);

    companyInfoApi.treeList().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });

    roleApi.selectAll().then(function(response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.roleResult = jsonData.data;
      }
    });
  },
  components: {
    "user-detail": UserDetail,
    "el-select-tree": SelectTree,
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  padding: 10px;
  text-align: left;
}
</style>